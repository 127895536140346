// ? TabsItems
import { mapActions, mapGetters } from 'vuex';
// ? SERVICE
import service        from '@/services/soporte'
import DialogForm     from '@/components/Dialogs/DialogForm/DialogForm.vue'

var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "AssignTicket",
    components : {
        DialogForm
    },
    props : {
        dialogAssign : {
            type    : Boolean,
            default : false
        },
        isEditAssign : {
            type    : Boolean,
            default : false
        },
        dataTicket : {
            type    : Object,
            default : () => {}
        },
    },
    data : () => ({
        valid           : true,
        lazy            : false,
        idTrTicket      : -1,
        idCtTipoServ    : 0,
        idRlTicketResp  : -1,
        idRlRespAux     : -1,
        idRlReqTicket   : -1,
        responsable     : undefined,
        auxiliar        : undefined,
        equipoAdic      : undefined,
        estatus         : undefined,
        instruccion     : undefined,
        updated_at      : undefined,
        oldObject       : {},
        items           : {
            responsable : [],
            auxiliar    : [],
            equipoAdic  : [],
        },
        pickerAsign     : {
            menu    : false,
            date    : currentDate
        },
        pickerAttention : {
            menu    : false,
            date    : currentDate
        },
        errores         : {
            responsable     : false,
            pickerAsign     : false,
            pickerAttention : false
        },
        rules           : {
            responsable     : [ v => !!v || 'El responsable es obligatorio' ],
            pickerAsign     : [ v => !!v || 'La fecha de asignación es obligatoria' ],
            pickerAttention : [ v => !!v || 'La fecha de atención es obligatoria' ]
        }
    }),
    watch : {
        'dataTicket' : function(){
            this.idTrTicket             = this.dataTicket.id_soporteTrTicket;
            this.idCtTipoServ           = this.dataTicket.id_soporteCtServicio;
            this.updated_at             = this.dataTicket.updated_at;
            this.estatus                = this.dataTicket.estatus;

            // leemos los items para los drops
            this.readDataAsignacionItems();

            if( this.isEditAssign ) // si es edición se consulta la información
                this.readDataAsignacion();

        }
    },
    computed : {
        ...mapGetters( "Ticket", { dataSource : "getDataSource"} )
    },
    methods : {
        ...mapActions("DialogForm",["setDialog"]),
        ...mapActions( "Ticket", ["setDataSource"] ),
        getTitle() {
            return "Asignación";
        },
        eventClose () {
            this.reset();
            // this.$emit("closeDialogAssign", false)
            this.setDialog({
                key    : "dlgAssign",
                status : false
            });
        },
        eventGen () {
            !this.isEditAssign ? this.createAsignacion() : this.updateAsignacion();
        },
        validate () {
            return this.$refs.form.validate()
        },
        reset () {
            this.$refs.form.reset();
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        createAsignacion(){
            let arrInfo = [];
            if( this.validate() ){
                let obj = this.buildObject();
                arrInfo.push( this.$JsTable.getFormat( "soporteTrTicket", "Update", [ obj.objTrTicket ], ["id_soporteTrTicket"] ) );
                arrInfo.push( this.$JsTable.getFormat( "soporteRlTicketResponsable", "Insert", [ obj.objRlTicketResp ], ["id_soporteRlTicketResponsable"] ) );
                if( this.equipoAdic != undefined )
                    arrInfo.push( this.$JsTable.getFormat( "soporteRlRequerimientoTicket", "Insert", [ obj.objRlRequerimiento ], ["id_soporteRlRequerimientoTicket"] ) );
                if( this.auxiliar != undefined )
                    arrInfo.push( this.$JsTable.getFormat( "soporteRlResponsable_Auxiliar", "Insert", [ obj.objRlAuxiliar ], ["id_soporteRlResponsable_Auxiliar"] ) );

                service.SopC_asignarTicket( arrInfo ).then( result => {
                    if( this.$Msg.fn( {data : result, message : "Asignación creada correctamente" } )) {
                        this.eventClose();
                        this.readTableTicket();
                    }
                })
            }
        },
        updateAsignacion(){
            let arrInfo         = [];
            let rlTicketResp    = []; 
            let rlAuxiliar      = [];
            let rlRequerimiento = [];
            if( this.validate() ){
                let obj = this.buildObject();
            
                rlTicketResp = this.$fn.getDiffObj( [this.oldObject.objRlTicketResp], [obj.objRlTicketResp], ["id_soporteRlTicketResponsable"] );
                if( rlTicketResp != 0 )
                    arrInfo.push( this.$JsTable.getFormat( "soporteRlTicketResponsable", "Update", rlTicketResp, ["id_soporteRlTicketResponsable"] ) );
                
                rlAuxiliar   = this.$fn.getDiffObj( [this.oldObject.objRlAuxiliar], [obj.objRlAuxiliar], ["id_soporteRlResponsable_Auxiliar"] );
                if( rlAuxiliar != 0 )
                    arrInfo.push( this.$JsTable.getFormat( "soporteRlResponsable_Auxiliar", "Update", rlAuxiliar, ["id_soporteRlResponsable_Auxiliar"] ) );
                
                rlRequerimiento   = this.$fn.getDiffObj( [this.oldObject.objRlRequerimiento], [obj.objRlRequerimiento], ["id_soporteRlRequerimientoTicket"] );
                if( rlRequerimiento != 0 )
                    arrInfo.push( this.$JsTable.getFormat( "soporteRlRequerimientoTicket", "Update", rlRequerimiento, ["id_soporteRlRequerimientoTicket"] ) );

                if( arrInfo.length > 0 ){
                    arrInfo.push( this.$JsTable.getFormat( "soporteTrTicket", "Update", [ obj.objTrTicket ], ["id_soporteTrTicket"] ) );
                    service.SopU_actulizarTicket( arrInfo ).then( result => {
                        if( this.$Msg.fn( {data : result, message : "Asignación actualizada correctamente" } )) {
                            this.eventClose();
                            this.readTableTicket();
                        }
                    })
                } else
                    this.$Msg.error( "No se ha realizado ningún cambio" )
            }
        },
        buildObject () {
            let objRlAuxiliar       = {};
            let objRlRequerimiento  = {};
            if( this.auxiliar != undefined ){
                objRlAuxiliar = {
                    id_soporteRlResponsable_Auxiliar    :   this.idRlRespAux,
                    id_soporteRlTicketResponsable       :   this.idRlTicketResp,
                    id_adminCtUser                      :   this.auxiliar
                }
            }
            if( this.equipoAdic != undefined ){
                objRlRequerimiento = {
                    id_soporteRlRequerimientoTicket :   this.idRlReqTicket,
                    id_soporteTrTicket              :   this.idTrTicket,
                    id_soporteCtRequerimiento       :   this.equipoAdic
                }
            }
            let objRlTicketResp = {
                id_soporteRlTicketResponsable   :   this.idRlTicketResp,
                id_soporteTrTicket              :   this.idTrTicket,
                id_soporteCtResponsable         :   this.responsable,
                fechaAsignacion                 :   this.pickerAsign.date,
                fechaAtencionProgramada         :   this.pickerAttention.date,
                fechaAtencionInicio             :   this.pickerAttention.date,
                instrucciones                   :   this.instruccion,
                activo                          :   1
            }
            let objTrTicket = {
                id_soporteTrTicket  : this.idTrTicket,
                id_baseCtEstatus    : 2,
                id_soporteCtServicio: this.idCtTipoServ,
                updated_at          : this.updated_at
            }

            return { objRlAuxiliar, objRlRequerimiento, objRlTicketResp, objTrTicket };
        },
        readTableTicket() {
            service.SopR_dataSourceTickets().then( result => {
                this.setDataSource( this.$JsTable.toObj( result.data ) );
            } )
        },
        async readDataAsignacionItems(){
            await service.SopR_dataAsignarTicket( { idCtTipoServ : this.idCtTipoServ } ).then( result => {
                this.items.responsable = this.$JsTable.toObj( result.data.responsable );
                this.items.auxiliar    = this.$JsTable.toObj( result.data.users );
                this.items.equipoAdic  = this.$JsTable.toObj( result.data.adicional );
            } );
        },
        async readDataAsignacion(){
            await service.SopR_dataAsignacion( { id_soporteTrTicket : this.idTrTicket } ).then( result => {
                let dataAssignInfo = this.$JsTable.toObj( result.data.dataAsignacion );
                if( dataAssignInfo.length > 0 ){
                    let dateAssign = dataAssignInfo[0].fechaAsignacion.split('T');
                    let dateAttention = dataAssignInfo[0].fechaAtencionInicio.split('T');

                    this.responsable            = dataAssignInfo[0].responsable;
                    this.auxiliar               = dataAssignInfo[0].auxiliar;
                    this.equipoAdic             = dataAssignInfo[0].equipoAdic;
                    this.estatus                = this.estatus;
                    this.instruccion            = dataAssignInfo[0].instruccion;
                    this.pickerAsign.date       = dateAssign[0];
                    this.pickerAttention.date   = dateAttention[0];
                    this.idRlTicketResp         = dataAssignInfo[0].idRlTicketResp;
                    this.idRlRespAux            = dataAssignInfo[0].idRlRespAux;
                    this.idRlReqTicket          = dataAssignInfo[0].idRlReqTicket;
                    this.oldObject = this.buildObject();
                }
            } )
        }
    },
    created() {

    },
    mounted() {
    }
}
